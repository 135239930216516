import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { simulatorQuestionDTO } from "../../../../../dtos/simulatorQuestionDTO";
import { differenceBetweenHours } from "../../../../../utils/utils";
import { useSimulatorUser } from "../../../hooks/useSimulatorUser";
import { useEffect } from "react";

type Props = {
  data: simulatorQuestionDTO;
  is_open: boolean;
};
export function FinishSimulatorModal({ data, is_open, ...rest }: Props) {
  const { dataList, loading, refetch } = useSimulatorUser();

  useEffect(() => {
    if (data.simulator.progress === "finished") {
      refetch(data.simulator.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function openHerf() {
    window.location.href = "/simuladores";
  }

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={() => {
          openHerf();
        }}
        isCentered
        closeOnOverlayClick={false}
        {...rest}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader
            textAlign="center"
            style={{
              color: "#03A679",
              fontSize: 16,
              fontFamily: "Kanit",
              fontWeight: 600,
            }}
          >
            Resultado do simulado
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={10}>
            {loading && (
              <Flex
                style={{
                  height: 200,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                width={["100%", "100%", "400px"]}
              >
                <Spinner
                  color="green.600"
                  emptyColor="white"
                  thickness="4px"
                  size="xl"
                  h={100}
                  w={100}
                />
              </Flex>
            )}
            {!loading && dataList && dataList.length > 0 && (
              <Flex
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
                flexDirection={["column", "column", "column"]}
              >
                <Flex>
                  <Text
                    style={{
                      color: "#03A679",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    Tempo do simulado:
                  </Text>
                  <Text
                    style={{
                      color: "#232323",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 600,
                      marginLeft: 5,
                    }}
                  >
                    {differenceBetweenHours(
                      data.simulator.date_started,
                      data.simulator.date_finished
                    )}
                  </Text>
                </Flex>
                <Flex mt={2}>
                  <Text
                    style={{
                      color: "#03A679",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    Quantidade de acertos:
                  </Text>
                  <Text
                    style={{
                      color: "#232323",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 600,
                      marginLeft: 5,
                    }}
                  >
                    {dataList.filter((item) => item.reply === "success").length}
                  </Text>
                </Flex>
                <Flex mt={2}>
                  <Text
                    style={{
                      color: "#03A679",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    Quantidade de erros:
                  </Text>
                  <Text
                    style={{
                      color: "#232323",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 600,
                      marginLeft: 5,
                    }}
                  >
                    {dataList.filter((item) => item.reply === "error").length}
                  </Text>
                </Flex>
                <Flex mt={2}>
                  <Text
                    style={{
                      color: "#03A679",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    Não respondidas:
                  </Text>
                  <Text
                    style={{
                      color: "#232323",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 600,
                      marginLeft: 5,
                    }}
                  >
                    {
                      dataList.filter((item) => item.reply === "no_reply")
                        .length
                    }
                  </Text>
                </Flex>
                <Button
                  type="submit"
                  bg="green.500"
                  color="white"
                  style={{
                    borderRadius: 53,
                    height: 44,
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                  _hover={{
                    bg: "green.500",
                  }}
                  mt="6"
                  size="lg"
                  onClick={() => {
                    openHerf();
                  }}
                >
                  Sair do simulado
                </Button>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
