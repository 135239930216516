import { Flex, useToast } from "@chakra-ui/react";
import { Title } from "../../../components/Title";
import { HeaderQuestions } from "../components/header";
import { QuestionTrueOrFalse } from "../typeQuestions/questionTrueOrFalse";
import { QuestionMultipleChoice } from "../typeQuestions/questionMultipleChoice";
import { useParams } from "react-router-dom";
import { useShowSimulator } from "../hooks/useShowSimulator";
import { FullLoading } from "../../../components/fullLoading";
import { Comments } from "../comments";
import { QuestionBlankSpace } from "../typeQuestions/questionBlankSpace";
import { FinishSimulatorModal } from "./modal/finishSimulatorModal";
import { useState } from "react";
import { api } from "../../../service/api";
import { AnswerToQuestion } from "./modal/answerToQuestion";

export function SimulatorQuestion() {
  let { id } = useParams();

  const toast = useToast();

  const [loadingFinash, setLoadingFinash] = useState(false);
  const [isModalQuestion, setIsModalQuestion] = useState(false);

  const { data, loading, error, refetch } = useShowSimulator(id as string);

  function formatNumer(quantity: number | null) {
    if (quantity && quantity > 0) {
      let newQuantity = quantity > 9 ? quantity : `0${quantity}`;
      return newQuantity;
    } else {
      return 0;
    }
  }

  async function finashSimulator() {
    try {
      setLoadingFinash(true);
      await api.post("simulator/finished", { Simulator_id: id });
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Simulador finalizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      refetch();
    } catch (error) {
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Não foi finalizar o simulador.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingFinash(false);
    }
  }

  return (
    <Flex w="100%" flexDirection="column">
      {(loading || loadingFinash) && <FullLoading />}

      {!loading && !error && data && data.simulator && data.simulator.id && (
        <>
          <FinishSimulatorModal
            data={data}
            is_open={data.simulator.progress === "finished"}
          />

          <AnswerToQuestion
            data={data}
            is_open={isModalQuestion}
            onClose={() => {
              setIsModalQuestion(false);
            }}
          />

          <Flex style={{ marginTop: 48, flexDirection: "column" }}>
            <Title title={data.simulator.title} />
          </Flex>
          <Flex style={{ marginTop: 17 }} width={["100%", "100%", "auto"]}>
            <HeaderQuestions
              onCancel={() => {
                finashSimulator();
              }}
              questionCurrent={formatNumer(data.paginations.total).toString()}
              questionActual={formatNumer(data.paginations.current).toString()}
              timerQuestion={data.simulatorQuestion.question.time_in_seconds}
            />
          </Flex>
          <Flex style={{ marginTop: 30 }}>
            {data.simulatorQuestion.question.content_type ===
              "true_or_false" && (
              <QuestionTrueOrFalse
                simulatorQuestion={data}
                onUpdate={() => {
                  refetch();
                }}
              />
            )}
            {data.simulatorQuestion.question.content_type ===
              "multiple_choice" && (
              <QuestionMultipleChoice
                simulatorQuestion={data}
                onUpdate={() => {
                  refetch();
                }}
              />
            )}

            {data.simulatorQuestion.question.content_type === "blank_space" && (
              <QuestionBlankSpace
                simulatorQuestion={data}
                onUpdate={() => {
                  refetch();
                }}
              />
            )}
          </Flex>

          <Flex
            style={{
              marginTop: 30,
              width: "100%",
              borderTopWidth: 5,
              borderColor: "#D9D9D9",
            }}
            maxWidth={["100%", "100%", "1300px"]}
          >
            <Flex style={{ marginTop: 30 }} width={["100%", "100%", "auto"]}>
              <Comments
                question_id={data.simulatorQuestion.question_id}
                onOpen={() => {
                  setIsModalQuestion(true);
                }}
              />
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
}
