import {
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Title } from "../../components/Title";
import { RiArrowRightFill, RiDeleteBin7Fill, RiPlayFill } from "react-icons/ri";
import { useListSimulator } from "./hooks/useListSimulator";
import { FullLoading } from "../../components/fullLoading";
import { formatedMonthDay } from "../../utils/utils";
import { useState } from "react";
import { api } from "../../service/api";

export function Simulators() {
  const toast = useToast();
  const { dataList, loading, error, refetch } = useListSimulator();
  const [loadingDelete, setLoadingDelete] = useState(false);

  async function deleteSimulator(id: string) {
    try {
      setLoadingDelete(true);
      await api.delete(`simulators/delete/${id}`);
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Simulador removido com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      refetch();
    } catch (error) {
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Não foi removier o simulador.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingDelete(false);
    }
  }

  return (
    <>
      {(loading || loadingDelete) && <FullLoading />}
      {!loading && !error && dataList && (
        <Flex w="100%" flexDirection="column">
          <Flex
            style={{ flexDirection: "column" }}
            marginTop={["20px", "20px", "48px"]}
          >
            <Title title="Meus simulados" />
          </Flex>

          {!loading && (
            <Button
              as="a"
              bg="green.500"
              color="white"
              style={{
                borderRadius: 53,
                height: 44,
                fontSize: 16,
                fontWeight: 600,
              }}
              width={["100%", "100%", "250px"]}
              _hover={{
                bg: "green.500",
              }}
              mt="6"
              size="lg"
              rightIcon={
                <Icon fontSize="20px" as={RiArrowRightFill} color="#fff" />
              }
              href="/simulador/criar"
            >
              Nova simulado
            </Button>
          )}

          <Flex
            style={{ gap: 35, flexWrap: "wrap" }}
            marginTop={["20px", "20px", "50px"]}
          >
            {dataList.map((item, index) => {
              return (
                <Flex
                  key={index}
                  style={{
                    width: "247.88px",
                    minHeight: "142px",
                    borderRadius: 10,
                    borderWidth: 2,
                    borderColor: "#0577BE",
                    flexDirection: "column",
                    padding: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <Flex style={{ flexDirection: "column" }}>
                    <Flex
                      style={{
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          color: "#0577BE",
                          fontSize: 18,
                          fontFamily: "Kanit",
                          fontWeight: 600,
                          width: 137,
                        }}
                      >
                        {formatedMonthDay(item.created_at.split("T")[0])}
                      </Text>

                      <Box
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          deleteSimulator(item.id);
                        }}
                      >
                        <Icon
                          fontSize="16px"
                          as={RiDeleteBin7Fill}
                          color="#D9D9D9"
                        />
                      </Box>
                    </Flex>

                    <Flex style={{ flexDirection: "column" }}>
                      <Flex>
                        <Text
                          style={{
                            color: "#818181",
                            fontSize: 12,
                            fontFamily: "Kanit",
                            fontWeight: 300,
                            width: 137,
                          }}
                        >
                          {item.code}
                        </Text>
                      </Flex>

                      <Flex style={{ marginTop: 20 }}>
                        <Text
                          style={{
                            color: "#0577BE",
                            fontSize: 12,
                            fontFamily: "Kanit",
                            fontWeight: 600,
                            width: 137,
                            lineHeight: 1,
                          }}
                        >
                          nome do simulado:
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex style={{ marginBottom: 10, marginTop: 10 }}>
                      <Text
                        style={{
                          color: "#232323",
                          fontSize: 18,
                          fontFamily: "Kanit",
                          fontWeight: 600,
                          width: 200,
                          lineHeight: 1.2,
                        }}
                      >
                        {item.title}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex>
                    <Link
                      href={`simuladores/questao/${item.id}?page_question=1`}
                    >
                      <Flex
                        style={{
                          width: "86px",
                          height: "27px",
                          backgroundColor: "#0577BE",
                          borderRadius: 13,
                          alignItems: "center",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: "#F5F5F5",
                            fontSize: 14,
                            fontFamily: "Kanit",
                            fontWeight: 500,
                            marginRight: 5,
                          }}
                        >
                          Iniciar
                        </Text>
                        <Icon fontSize="14px" as={RiPlayFill} color="#F5F5F5" />
                      </Flex>
                    </Link>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      )}
    </>
  );
}
