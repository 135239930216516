import { useEffect, useState } from "react";
import { Box, Button, Flex, Icon, Text, useToast } from "@chakra-ui/react";
import { Title } from "../../../../../components/Title";
import { InputSelectBox } from "../../../../../components/form/inputSelectBox";
import { InputBox } from "../../../../../components/form/inputBox";
import { RiSaveLine } from "react-icons/ri";
import { SelectTypeDTO } from "../../../../../dtos/SelectTypeDTO";
import { year } from "../../../../../data/data";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { api } from "../../../../../service/api";
import { typeQuestion } from "../../../../../utils/utils";
import { useListCategories } from "../../../../extra/categories/hooks/useListCategories";

interface ITip {
  position: number;
  title: string;
}

type createFormData = {
  content_type: string;
  enunciation: string;
  answer_sheet?: string;
  answer_to_the_question?: string;
  text?: string;
  tips_1?: string;
  tips_2?: string;
  tips_3?: string;
  alternative_1?: string;
  alternative_2?: string;
  alternative_3?: string;
  alternative_4?: string;
  alternative_5?: string;
  discipline: string;
  subject: string;
  jury: string;
  modality?: string;
  year: string;
  tags: string;
  time_in_seconds: string;
};

const createFormSchema = yup.object().shape({
  content_type: yup.string().required("Campo obrigatório."),
  enunciation: yup.string().required("Campo obrigatório."),
  answer_sheet: yup.string(),
  answer_to_the_question: yup.string(),
  tips_1: yup.string(),
  tips_2: yup.string(),
  tips_3: yup.string(),
  text: yup.string(),
  alternative_1: yup.string(),
  alternative_2: yup.string(),
  alternative_3: yup.string(),
  alternative_4: yup.string(),
  alternative_5: yup.string(),
  discipline: yup.string().required("Campo obrigatório."),
  subject: yup.string().required("Campo obrigatório."),
  jury: yup.string().required("Campo obrigatório."),
  modality: yup.string(),
  year: yup.string().required("Campo obrigatório."),
  tags: yup.string().required("Campo obrigatório."),
  time_in_seconds: yup.string().required("Campo obrigatório."),
});

export function CreateManualQuestion() {
  const toast = useToast();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormSchema) });

  const { loading: loadingCategory, refetch: refetchCategory } =
    useListCategories();

  const [disciplineType, setDisciplineType] = useState<SelectTypeDTO[]>([]);
  const [subjectType, setSubjectType] = useState<SelectTypeDTO[]>([]);
  const [juryData, setJuryData] = useState<SelectTypeDTO[]>([]);

  const contentType = watch("content_type");

  async function getDiciplineType() {
    const dataDiscipline = await refetchCategory("discipline");

    const newSelectTypeDTO: SelectTypeDTO[] = [];

    if (dataDiscipline) {
      for (let index = 0; index < dataDiscipline.length; index++) {
        const element = dataDiscipline[index];
        newSelectTypeDTO.push({
          label: element.name,
          value: element.name,
        });
      }
    }
    setDisciplineType(newSelectTypeDTO);
  }

  async function getJury() {
    const dataDiscipline = await refetchCategory("jury");

    const newSelectTypeDTO: SelectTypeDTO[] = [];

    if (dataDiscipline) {
      for (let index = 0; index < dataDiscipline.length; index++) {
        const element = dataDiscipline[index];
        newSelectTypeDTO.push({
          label: element.name,
          value: element.name,
        });
      }
    }
    setJuryData(newSelectTypeDTO);
  }

  useEffect(() => {
    getDiciplineType();
    getJury();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getSubjectType(discipline: string) {
    const newSelectTypeDTO: SelectTypeDTO[] = [];

    const values = await refetchCategory("subject", discipline);

    if (values) {
      for (let index = 0; index < values.length; index++) {
        const element = values[index];
        newSelectTypeDTO.push({
          label: element.name,
          value: element.name,
        });
      }
    }

    setSubjectType(newSelectTypeDTO);
  }

  function mapTipsOnArray(tips: string) {
    const tipsData = tips.split("|");

    const newTipData: ITip[] = [];

    for (let index = 0; index < tipsData.length; index++) {
      const element = tipsData[index];

      newTipData.push({
        position: index + 1,
        title: element,
      });
    }

    return newTipData;
  }

  function mapAlternativeOnArray(value: createFormData) {
    return [
      {
        title: "A",
        description: value.alternative_1,
        is_correct: value.answer_sheet === "A",
      },
      {
        title: "B",
        description: value.alternative_2,
        is_correct: value.answer_sheet === "B",
      },
      {
        title: "C",
        description: value.alternative_3,
        is_correct: value.answer_sheet === "C",
      },
      {
        title: "D",
        description: value.alternative_4,
        is_correct: value.answer_sheet === "D",
      },
      {
        title: "E",
        description: value.alternative_5,
        is_correct: value.answer_sheet === "E",
      },
    ];
  }

  function mapAlternativeIsValidaded(value: createFormData) {
    return (
      value.alternative_1 &&
      value.alternative_2 &&
      value.alternative_3 &&
      value.alternative_4 &&
      value.alternative_5
    );
  }

  const handleCreate: SubmitHandler<createFormData> = async (value) => {
    try {
      let tips = `${value.tips_1 ? value.tips_1 + "|" : ""}${
        value.tips_2 ? value.tips_2 + "|" : ""
      }${value.tips_3 ?? ""}`;

      const answer_tips = mapTipsOnArray(tips);
      let alternatives = null;

      if (
        value.content_type === "Múltipla escolha" &&
        !mapAlternativeIsValidaded(value)
      ) {
        toast({
          position: "top-right",
          title: "Questão",
          description: "Informe as alternativas de A até E",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      if (value.content_type === "Múltipla escolha") {
        alternatives = mapAlternativeOnArray(value);
      }

      if (
        (value.content_type === "Múltipla escolha" ||
          value.content_type === "Verdadeiro ou Falso") &&
        !value.answer_sheet
      ) {
        toast({
          position: "top-right",
          title: "Questão",
          description: "Selecione uma resposta",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      if (
        (value.content_type === "Múltipla escolha" ||
          value.content_type === "Verdadeiro ou Falso") &&
        !value.answer_sheet
      ) {
        toast({
          position: "top-right",
          title: "Questão",
          description: "Selecione uma resposta",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      const data = {
        ...value,
        tips,
        answer_tips,
        alternatives,
        content_type: typeQuestion(value.content_type),
      };

      await api.post("/question/create", data);

      toast({
        position: "top-right",
        title: "Questão",
        description: "Questão cadastrado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      window.location.reload();
    } catch (error) {
      toast({
        position: "top-right",
        title: "Questão",
        description: "Não foi possível cadastrar o questão.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex w="100%" flexDirection="column">
      <Flex
        style={{ flexDirection: "column" }}
        marginTop={["20px", "20px", "48px"]}
      >
        <Title title="Cadastrar Questões manual" />

        <Flex
          as="form"
          onSubmit={handleSubmit(handleCreate)}
          style={{
            flexDirection: "column",
          }}
          marginTop={["20px", "20px", "60px"]}
          width={["100%", "100%", "675px"]}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: "#5F5F5F",
              fontFamily: "Kanit",
            }}
          >
            Vamos Começar?
          </Text>
          <Text
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: "#A4A4A4",
              fontFamily: "Kanit",
              marginTop: 20,
            }}
          >
            Responda as perguntas para criar as questões / Resumos
          </Text>

          <Box style={{ marginTop: 25 }}>
            <Controller
              control={control}
              name="content_type"
              render={({ field: { onChange, value } }) => (
                <InputSelectBox
                  width="100%"
                  label="Qual tipo de contéudo você quer gerar?"
                  name="content_type"
                  options={[
                    {
                      label: "Múltipla escolha",
                      value: "Múltipla escolha",
                    },
                    {
                      label: "Verdadeiro ou Falso",
                      value: "Verdadeiro ou Falso",
                    },
                    {
                      label: "Espaço em branco",
                      value: "Dissertativa Espaço em branco",
                    },
                  ]}
                  onChange={(value) => {
                    onChange(value.value);
                  }}
                  placeholder="Questões de concurso; Múltipla escolha"
                  error={errors.content_type}
                />
              )}
            />
          </Box>

          <Box style={{ marginTop: 25 }}>
            <InputBox
              label="Enunciado"
              placeholder="Digite aqui a questão"
              {...register("enunciation")}
              error={errors.enunciation}
            />
          </Box>

          {contentType === "Múltipla escolha" && (
            <>
              <Box style={{ marginTop: 25 }}>
                <InputBox
                  label="Alternativa A"
                  placeholder="Alternativa A"
                  {...register("alternative_1")}
                  error={errors.alternative_1}
                />
              </Box>

              <Box style={{ marginTop: 25 }}>
                <InputBox
                  label="Alternativa B"
                  placeholder="Alternativa B"
                  {...register("alternative_2")}
                  error={errors.alternative_2}
                />
              </Box>

              <Box style={{ marginTop: 25 }}>
                <InputBox
                  label="Alternativa C"
                  placeholder="Alternativa C"
                  {...register("alternative_3")}
                  error={errors.alternative_3}
                />
              </Box>

              <Box style={{ marginTop: 25 }}>
                <InputBox
                  label="Alternativa D"
                  placeholder="Alternativa D"
                  {...register("alternative_4")}
                  error={errors.alternative_4}
                />
              </Box>

              <Box style={{ marginTop: 25 }}>
                <InputBox
                  label="Alternativa E"
                  placeholder="Alternativa E"
                  {...register("alternative_5")}
                  error={errors.alternative_5}
                />
              </Box>
            </>
          )}

          {contentType === "Dissertativa Espaço em branco" && (
            <Box style={{ marginTop: 25 }}>
              <InputBox
                label="Texto"
                placeholder="Digite o texto {{resposta}}"
                {...register("text")}
                error={errors.text}
              />
            </Box>
          )}

          {contentType !== "Dissertativa Espaço em branco" && (
            <>
              <Box style={{ marginTop: 25 }}>
                <InputBox
                  label="Gabarito"
                  placeholder="Gabarito..."
                  {...register("answer_to_the_question")}
                  error={errors.answer_to_the_question}
                />
              </Box>
              <Box style={{ marginTop: 25 }}>
                <Controller
                  control={control}
                  name="answer_sheet"
                  render={({ field: { onChange, value } }) => (
                    <InputSelectBox
                      width="100%"
                      label="Resposta"
                      name="answer_sheet"
                      options={
                        contentType === "Múltipla escolha"
                          ? [
                              {
                                label: "A",
                                value: "A",
                              },
                              {
                                label: "B",
                                value: "B",
                              },
                              {
                                label: "C",
                                value: "C",
                              },
                              {
                                label: "D",
                                value: "D",
                              },
                              {
                                label: "E",
                                value: "E",
                              },
                            ]
                          : [
                              {
                                label: "Verdadeiro",
                                value: "Verdadeiro",
                              },
                              {
                                label: "Falso",
                                value: "Falso",
                              },
                            ]
                      }
                      onChange={(value) => {
                        onChange(value.value);
                      }}
                      placeholder="Resposta"
                      error={errors.content_type}
                    />
                  )}
                />
              </Box>
            </>
          )}

          <Box style={{ marginTop: 25 }}>
            <InputBox
              label="Dica 01"
              placeholder="Dica 01"
              {...register("tips_1")}
              error={errors.tips_1}
            />
          </Box>

          <Box style={{ marginTop: 25 }}>
            <InputBox
              label="Dica 02 "
              placeholder="Dica 02"
              {...register("tips_2")}
              error={errors.tips_2}
            />
          </Box>

          <Box style={{ marginTop: 25 }}>
            <InputBox
              label="Dica 03"
              placeholder="Dica 03"
              {...register("tips_3")}
              error={errors.tips_3}
            />
          </Box>

          <Text
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: "#5F5F5F",
              fontFamily: "Kanit",
              marginTop: 50,
            }}
          >
            Agora, vamos classificar o conteúdo, essa etapa é muito importante,
            faça com atenção!
          </Text>

          <Box style={{ marginTop: 25 }}>
            <Controller
              control={control}
              name="discipline"
              render={({ field: { onChange, value } }) => (
                <InputSelectBox
                  width="100%"
                  label="Disciplinas"
                  name="discipline"
                  isLoading={loadingCategory}
                  options={disciplineType}
                  onChange={(value) => {
                    getSubjectType(value.value);
                    onChange(value.value);
                  }}
                  placeholder="Administração geral; Direito Constitucional..."
                  error={errors.discipline}
                />
              )}
            />
          </Box>
          <Box style={{ marginTop: 25 }}>
            <Controller
              control={control}
              name="subject"
              render={({ field: { onChange, value } }) => (
                <InputSelectBox
                  width="100%"
                  label="Assuntos"
                  isLoading={loadingCategory}
                  name="subject"
                  options={subjectType}
                  onChange={(value) => {
                    onChange(value.value);
                  }}
                  placeholder="Assunto"
                  error={errors.subject}
                />
              )}
            />
          </Box>
          <Box style={{ marginTop: 25 }}>
            <Controller
              control={control}
              name="jury"
              render={({ field: { onChange, value } }) => (
                <InputSelectBox
                  width="100%"
                  label="Banca"
                  name="jury"
                  options={juryData}
                  isLoading={loadingCategory}
                  onChange={(value) => {
                    onChange(value.value);
                  }}
                  placeholder="FGV"
                  error={errors.jury}
                />
              )}
            />
          </Box>
          <Box style={{ marginTop: 25 }} display="none">
            <Controller
              control={control}
              name="modality"
              render={({ field: { onChange, value } }) => (
                <InputSelectBox
                  width="100%"
                  label="Modalidade"
                  name="modality"
                  options={[
                    {
                      label: "TM",
                      value: "TM",
                    },
                    {
                      label: "Especialista",
                      value: "Especialista",
                    },
                    {
                      label: "Inteligência Artificial",
                      value: "Inteligência Artificial",
                    },
                    {
                      label: "Elaboração Própria",
                      value: "Elaboração Própria",
                    },
                  ]}
                  onChange={(value) => {
                    onChange(value.value);
                  }}
                  placeholder="TM / Especialista / Inteligência Artificial / Elaboração Própria"
                  error={errors.modality}
                />
              )}
            />
          </Box>
          <Box style={{ marginTop: 25 }}>
            <Controller
              control={control}
              name="year"
              render={({ field: { onChange, value } }) => (
                <InputSelectBox
                  width="100%"
                  label="Ano"
                  name="teste"
                  options={year}
                  onChange={(value) => {
                    onChange(value.value);
                  }}
                  placeholder="Selecione os anos de interesse"
                  error={errors.year}
                />
              )}
            />
          </Box>
          <Box style={{ marginTop: 25 }}>
            <InputBox
              label="Tags"
              placeholder="tags; tags 2; "
              {...register("tags")}
              error={errors.tags}
            />
          </Box>
          <Box style={{ marginTop: 25 }}>
            <Controller
              control={control}
              name="time_in_seconds"
              render={({ field: { onChange, value } }) => (
                <InputSelectBox
                  width="100%"
                  label="Tempo esperado de resposta (segundos)"
                  name="time_in_seconds"
                  options={[
                    {
                      label: "10 segundos",
                      value: "10",
                    },
                    {
                      label: "30 segundos",
                      value: "30",
                    },
                    {
                      label: "60 segundos",
                      value: "60",
                    },
                    {
                      label: "120 segundos",
                      value: "120",
                    },
                    {
                      label: "240 segundos",
                      value: "240",
                    },
                  ]}
                  onChange={(value) => {
                    onChange(value.value);
                  }}
                  placeholder="10 segundos / 30 segundos / 60 segundos / 120 segundos"
                  error={errors.time_in_seconds}
                />
              )}
            />
          </Box>

          <Flex
            style={{
              marginTop: 25,
              marginBottom: 80,
              alignItems: "flex-end",
              justifyContent: "flex-end",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Button
              type="submit"
              bg="green.500"
              color="white"
              isLoading={isSubmitting}
              style={{
                borderRadius: 53,
                height: 44,
                fontSize: 16,
                fontWeight: 600,
                marginBottom: 50,
              }}
              width={["100%", "100%", "193px"]}
              _hover={{
                bg: "green.500",
              }}
              mt="6"
              size="lg"
              rightIcon={<Icon fontSize="20px" as={RiSaveLine} color="#fff" />}
            >
              Salvar questão
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
