import { Flex } from "@chakra-ui/react";
import { QuestionInfo } from "../questionInfo";
import { ButtonCancel } from "../buttonCancel";
import { useEffect, useState } from "react";

type Props = {
  timerQuestion: number;
  questionActual: string;
  questionCurrent: string;
  onCancel: () => void;
};

export function HeaderQuestions({
  timerQuestion,
  questionActual,
  questionCurrent,
  onCancel,
}: Props) {
  const [timerOut, setTimerOut] = useState("00:00");

  function timerFullQuestion() {
    var duration: number = timerQuestion;

    let timer: number = duration;
    let minutes;
    let seconds;

    setInterval(function () {
      minutes = parseInt((timer / 60).toString(), 10);
      seconds = parseInt((timer % 60).toString(), 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      let textContent = minutes + ":" + seconds;

      if (textContent !== "NaN:NaN") {
        setTimerOut(textContent);
      }
      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  useEffect(() => {
    timerFullQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerQuestion]);

  return (
    <Flex width={["100%", "100%", "auto"]}>
      <Flex
        style={{ gap: 20 }}
        width={["100%", "100%", "auto"]}
        flexDirection={["column", "column", "row"]}
      >
        <QuestionInfo title="Tempo nesta questão:" info={timerOut} />
        <QuestionInfo
          title="Questões respondidas:"
          info={`/${questionCurrent.toString()}`}
          actualInfo={`${questionActual.toString()}`}
        />
        <ButtonCancel onCancel={onCancel} />
      </Flex>
    </Flex>
  );
}
