import { Flex, Text } from "@chakra-ui/react";
import { InputBox } from "../../../../../../components/form/inputBox";
import { QuestionsSimulatorInfoDTO } from "../../../../../../dtos/QuestionsSimulatorInfoDTO";

type Props = {
  data: QuestionsSimulatorInfoDTO;
  onChangeValue: (value: QuestionsSimulatorInfoDTO) => void;
};

export function BoxContentDiciplineAndSubject({ data, onChangeValue }: Props) {
  return (
    <Flex style={{ flexDirection: "column" }}>
      <Flex>
        <Text
          style={{
            color: "#0577BE",
            fontSize: 16,
            fontFamily: "Kanit",
            fontWeight: 400,
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          {`${data.discipline}:`}
        </Text>
      </Flex>
      <Flex
        style={{
          marginTop: "10px",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 20,
        }}
        width={["100%", "100%", "1000px"]}
      >
        {data.subjects.map((item, index) => {
          return (
            <Flex
              key={index}
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#5F5F5F",
                  fontSize: 14,
                  fontFamily: "Kanit",
                  fontWeight: 400,
                }}
              >{`${item.subject}:`}</Text>
              <Flex
                style={{
                  marginLeft: 5,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InputBox
                  name="teste"
                  width="100px"
                  textAlign="center"
                  placeholder=""
                  autoComplete="off"
                  onChange={(e) => {
                    let quantity = Number(e.target.value);

                    data.subjects[index].quantity = quantity;
                    onChangeValue(data);
                  }}
                  value={item.quantity}
                  // isDisabled={item.quantity === 0}
                  error={
                    item.quantity === 0
                      ? {
                          message: "",
                          type: "required",
                        }
                      : undefined
                  }
                />
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}
