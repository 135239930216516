import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { simulatorQuestionDTO } from "../../../../../dtos/simulatorQuestionDTO";

type Props = {
  data: simulatorQuestionDTO;
  is_open: boolean;
  onClose: () => void;
};
export function AnswerToQuestion({ data, is_open, onClose, ...rest }: Props) {
  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={() => {
          onClose();
        }}
        isCentered
        closeOnOverlayClick={false}
        {...rest}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader
            textAlign="center"
            style={{
              color: "#03A679",
              fontSize: 16,
              fontFamily: "Kanit",
              fontWeight: 600,
            }}
          >
            Gabarito
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={10}>
            <Flex
              style={{
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}
              flexDirection={["column", "column", "column"]}
            >
              <Flex style={{ marginBottom: "30px" }}>
                <Text
                  style={{
                    fontSize: 16,
                    fontFamily: "Kanit",
                    fontWeight: 400,
                    marginLeft: 5,
                  }}
                >
                  {data.simulatorQuestion.question.answer_to_the_question}
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
